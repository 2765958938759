<template>
  <lidaPage :columns="tableHead" :query="inquiryPageBuyer" :queryInfo="{isQueryProviderQuote:0}" ref="page" :formColumns="formColumns" subTitle="总询价单数">

    <template #inquiryAmt="scope">
      <div>￥{{ scope.row.inquiryAmt }}</div>
    </template>
    <template #documentState="scope">
      <div>{{ documentState[scope.row.documentState] }}</div>
    </template>
    <template #operation>
      <lida-table-column label="操作" width="220" fixed="right">
        <template #default="scope">
          <div style="display: flex;align-items: center;">
            <div style="margin-right: 15px;"><el-button @click="commands(scope.row, '生成报价单')" size="mini" type="primary">生成报价单</el-button></div>
          <el-button @click="commands(scope.row, '查看')" type="text">查看</el-button>
          <el-button @click="commands(scope.row, '撤销')" type="text"
            v-if="scope.row.documentState === 'WAIT_QUOTE'">撤销</el-button>
          <el-button @click="commands(scope.row, '删除')" type="text"
            v-if="scope.row.documentState === 'REVOKE'">删除</el-button>
          </div>
        </template>
      </lida-table-column>
    </template>
    <template #formButton>
      <el-button size="mini" type="primary" @click="handleAdd">新增询价单</el-button>
    </template>
  </lidaPage>
</template>
<script>
import { ElMessage, ElMessageBox } from "element-plus";
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState("purchaseMudule", ["documentStateDel", "documentState"]),
  },
  data() {
    return {
      /** 表头字段 */
      tableHead: [
        {
          label: "制单时间",
          prop: "documentTime",
        },
        {
          label: "询价单号",
          prop: "inquiryNo",
        },
        {
          label: "单据状态",
          prop: "documentState",
        },
        {
          label: "最终用户",
          prop: "inquiryEnterpriseName",
        },
        {
          label: "询价人",
          prop: "inquiryCustomerName",
        },
        {
          label: "联系方式",
          prop: "inquiryCustomerPhone",
        },
        {
          label: "询价品牌",
          prop: "inquiryBrands",
        },
        {
          label: "询价总金额",
          prop: "inquiryAmt",
        },
        {
          label: "项目名称",
          prop: "projectName",
        },
        {
          label: "报价有效期限",
          prop: "shelfLife",
        },
        {
          label: "报价数量",
          prop: "quoteNum",
        },
        {
          label: "报价备注",
          prop: "remarks",
        },
      ],
      formColumns: [
        {
          type: "date",
          prop: "time",
          label: "制单日期",
        },
        {
          type: "input",
          prop: "wd",
          label: "综合搜索",
        },
        {
          type: "select",
          label: '单据状态',
          prop: "documentStatus",
          options: [
            {
              value: "WAIT_QUOTE",
              label: "待报价",
            },
            {
              value: "COMPLETE_QUOTE",
              label: "已报价",
            },
            {
              value: "REVOKE",
              label: "已撤销",
            },
          ],
        },
      ],
      menusData: [
        {
          name: "查看",
        },
        {
          name: "撤销",
        },
        {
          name: "删除",
        },
      ],
    };
  },
  methods: {
    ...mapActions("purchaseMudule", [
      "removeInquiryReq", // 删除
      "revokeInquiryReq", // 撤销
      "inquiryPageBuyer", //买家询价列表
    ]),
    handleAdd() {
      //添加
      this.$router.push({
        path: "/purchase/addInquiryPage",
      });
    },
    commands(row, type) {
      //操作的点击事件
      switch (type) {
        case "删除":
          ElMessageBox.confirm(`是否要${type}该清单?`, "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
          })
            .then(() => {
              this.removeInquiryReq(row.id).then((res) => {
                let { code, data } = res.data;
                if (code === "0") {
                  ElMessage({
                    type: "success",
                    message: "删除成功",
                  });
                  this.$refs.page.refresh();
                }
              });
            })
            .catch(() => { });

          break;
        case "撤销":
          ElMessageBox.confirm(`是否要${type}该清单?`, "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
          })
            .then(() => {
              this.revokeInquiryReq(row.id).then((res) => {
                let { code, data } = res.data;
                if (code === "0") {
                  //请求成功
                  ElMessage({
                    type: "success",
                    message: "撤销成功",
                  });
                  this.$refs.page.refresh();
                }
              });
            })
            .catch(() => { });
          break;
        case "查看":
          this.$router.push({
            path: "/purchase/enquiryDetails",
            query: {
              id: row.id,
            },
          });
          break;
        case "生成报价单":
          this.$router.push({
            path: "/mysale/addOfferOrder",
            query: {
              inquiryId: row.id,
            },
          });
          break;
      }
    },
  },
};
</script>
